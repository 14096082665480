<template>
  <div class="news-detail">
    <img :src="loadUrl.model_bg" />
    <div class="detail-html"></div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue'
import { getStaticHtml, getOssUrls } from '@/api'
import { findSequences, findSequences2 } from '@/utils'
import router from '@/router';
import store from '@/store';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

onMounted(async () => {
  const relatedHtmlId: any = router.currentRoute.value.query.relatedHtmlId;
  getStaticHtml({ id: relatedHtmlId }).then((html: any) => {
    const resultKeys = findSequences(html);
    const resultSources = findSequences2(html);
    getOssUrls(resultKeys).then((res: any) => {
      resultKeys.forEach((urlKey, i) => {
        html = html.replace(resultSources[i], `source src="${res[i]}`)
      });
      (document.querySelector('.news-detail .detail-html') as any).innerHTML = html
    })
  })
  if (document.documentElement && document.documentElement.scrollTop) {
    document.documentElement.scrollTop = 0;
  } else if (document.body) {
    document.body.scrollTop = 0
  }

  const model_bg: any = await asynchronousRetrieval('webimg/images/solution/model-bg.png');
  loadUrl.value = { model_bg }
})
</script>
<style scoped lang="less">
.news-detail {
  position: relative;
  background-color: #EFEFEF;
  min-height: 720rem;
  background-repeat: no-repeat;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 39rem;
  box-sizing: border-box;

  &>img {
    width: 100%;
    height: auto;
    position: absolute;
    left: 0;
    top: 0;
  }

  &>.detail-html {
    position: relative;
    width: 720rem;
    min-height: 957rem;
    background: #fff;
    border-radius: 40rem;
    font-size: 15px;
    z-index: 1;
    margin-bottom: 70rem;
    padding: 0 10rem;
  }

  .title {
    position: relative;
    width: 720rem;
    height: 69rem;
    // line-height: 69rem;
    position: absolute;
    left: 50%;
    margin-left: -560rem;
    top: 140rem;
    text-align: left;
    padding-left: 104rem;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 14rem;
    color: #FFFFFF;

    &>span {
      position: relative;
      position: absolute;
      padding-left: 15rem;
      position: absolute;
      left: 35rem;
      top: 0;
      cursor: pointer;
      font-weight: 500;
      font-size: 14px;
      color: #FFFFFF;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        top: 27rem;
        width: 9rem;
        height: 15rem;
        background-image: url(~@/assets/images/solution/left.png);
        background-size: 100% 100%;
      }
    }

    span {
      height: 69rem;
      line-height: 69rem;
      vertical-align: middle;
    }
  }

  &::before {
    content: '';
    width: 1120rem;
    height: 126rem;
    background: rgba(0, 0, 0, 0.27);
    border-radius: 40rem;
    // opacity: 0.5;
    backdrop-filter: blur(13rem);
    position: absolute;
    left: 50%;
    margin-left: -560rem;
    top: 140rem;
  }
}

.news-bottom {
  width: 100%;
  height: 60rem;
  background-color: #EFEFEF;
}
</style>