<template>
  <div>
    <div class="header-video">
      <img :src="loadUrl.studio_banner" />
      <div class="video-text">
        <div class="logo-text">
          <span>混空</span>
          <span>Studio</span>
        </div>
        <div class="link-room">打造数实融合的空间计算平台</div>
      </div>
    </div>
    <div class="role-wrap">
      <div class="title">专业的元宇宙设计工具</div>
      <div class="title-tips">满足复杂场景设计需求</div>
      <div class="role-scene">
        <div>
          <div v-for="(item, index) in roleTextList" :class="item.activeClass" :key="index">
            <div class="role-title">{{ item.title }}</div>
            <div class="role-title-tips">{{ item.tips }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="advantage">
      <div class="title deep">我们的优势</div>
      <div class="title-tips deep">更低的创作门槛，更丰富的功能</div>
      <div class="m1">
        <div>
          <img class="m1-img" src="@/assets/images/studio/m1-l.png" />
          <div class="t1">更准确的空间定位</div>
          <div class="t2">自研空间定位算法，原米级视觉定位空间导航精准， 支持大空间定位</div>
        </div>
        <div>
          <img src="@/assets/images/studio/m1-r.png" />
          <div class="t1">更快速的交付部署</div>
          <div class="t2">SaaS化服务快速调整内容布局2天内完成空间构建现场体验式交付</div>
        </div>
      </div>
      <div class="m2">
        <img class="img2" src="@/assets/images/studio/m2-l.png" />
        <img class="img1" src="@/assets/images/studio/m2-m.png" />
        <img class="img3" src="@/assets/images/studio/m2-r.png" />
        <div class="t1">更简单的场景编辑</div>
        <div class="t2">3D场景地图， 可视化交互“拖拉拽“操作，所“建”即所得</div>
      </div>
      <div class="m3">
        <div>
          <div class="m3-icons">
            <img src="@/assets/images/studio/m3-l-1.png" />
            <img src="@/assets/images/studio/m3-l-2.png" />
            <img src="@/assets/images/studio/m3-l-3.png" />
          </div>
          <div class="t1">更多平台支持应用</div>
          <div class="t2">AR/MR/VR眼镜、移动端APP、小程序AR、Web AR</div>
        </div>
        <div>
          <div class="m3-r">
            <img src="@/assets/images/studio/m3-r-t.png" />
            <img src="@/assets/images/studio/m3-r-b.png" />
          </div>
          <div class="t1">更强的内容扩展性</div>
          <div class="t2">数字多媒体动画、特效手势互动、3D数字人</div>
        </div>
      </div>
    </div>
    <div class="solution">
      <div class="title">混空Studio使用教程</div>
      <div class="title-tips">一站式可视化编辑，轻松实现AR创作与开发</div>
      <div class="solution-content">
        <div>
          <div v-for="(item, index) in solutionBannerList" :key="index">
            <div>
              <img v-if="item.i_url" :src="item.i_url" />
              <video v-if="item.v_url" :src="item.v_url" autoplay loop muted playsinline webkit-playsinline></video>
            </div>
            <div>
              <img :src="item.a_url" />
              <span>{{ item.title }}</span>
              <div>
                <div v-for="(e, i) in item.content" :key="i">
                  <span>{{ e }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="package">
      <div>
        <div class="title deep">根据需求选择不同套餐</div>
        <div class="title-tips deep">使用我们全套XR开发工具，跨设备部署到每个项目</div>
        <div class="package-list">
          <div>
            <div v-for="(item, index) in packageList" :key="index" :class="item.className">
              <div>
                <div class="package-name">{{ item.name }}</div>
                <div class="package-tips">{{ item.tips }}</div>
                <div class="package-btn" @click="router.push('/counselling')">{{ item.btnText }}</div>
              </div>
              <div>
                <div v-for="(e, i) in item.content" :key="i">
                  <span class="package-list-icon">{{ e.title }}</span>
                  <span>{{ e.value }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, watch, onUnmounted } from 'vue';
import { asynchronousRetrieval } from '@/utils';
import router from '@/router';
const loadUrl: any = ref({})
const activeRoleIndex = ref(0)
const roleTextList = ref(
  [
    {
      title: '构建AR大场景',
      tips: 'SAAS化部署，无缝开发沉浸式世界里的增强现实内容，所有这些都在您的网络浏览器中创建和部署',
      url: require('@/assets/images/home/room-platform.png'),
      activeClass: 'room-bg'
    },
    {
      title: 'XR眼镜端',
      tips: '通过账号进行登陆并选择相应场景进入相关展示内容。根据现场虚与实的融合进行体验',
      url: require('@/assets/images/home/ar-rokid.png'),
      activeClass: 'glass-bg'
    },
    {
      title: '移动端',
      tips: '游客模式使用手机或Pad直接进行空间互动体验。(设备支持ARkit/ARCore)',
      url: loadUrl.value.web_platform,
      activeClass: 'web-bg'
    }
  ]
)
const solutionBannerList: any = ref(
  [
    {
      title: '采集地图',
      content: ['场地调研 现场拍摄 空间采集'],
      url: require('@/assets/images/studio/solution-l-icon1.png'),
      a_url: require('@/assets/images/studio/solution-l-icon1A.png'),
    },
    {
      title: '创建场景',
      content: ['设置互动区域 上传内容素材', '虚拟空间装修 支持小程序场景'],
      url: require('@/assets/images/studio/solution-l-icon2.png'),
      a_url: require('@/assets/images/studio/solution-l-icon2A.png'),
    },
    {
      title: '快速部署',
      content: ['生成账号权限 打包标准应用', '安装应用程序'],
      url: require('@/assets/images/studio/solution-l-icon3.png'),
      a_url: require('@/assets/images/studio/solution-l-icon3A.png'),
    },
    {
      title: '虚实融合体验',
      content: ['【眼镜端体验】 接入支持AR/MR/VR眼镜', '【移动端体验】 支持微信小程序体验'],
      url: require('@/assets/images/studio/solution-l-icon4.png'),
      a_url: require('@/assets/images/studio/solution-l-icon4A.png'),
    }
  ]
)
const packageList = [
  {
    name: '个人体验版',
    tips: '适用于个人零基础搭建AR场景',
    btnText: '立即咨询',
    className: 'l1',
    content: [
      {
        title: '场景数量',
        value: '3个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '1G'
      }
    ]
  },
  {
    name: '基础版',
    tips: '适用于中小型项目场景',
    btnText: '立即咨询',
    className: 'l2',
    content: [
      {
        title: '场景数量',
        value: '13个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '30G'
      }
    ]
  },
  {
    name: '专业版',
    tips: '适用于专业型项目场景',
    btnText: '立即咨询',
    className: 'l3',
    content: [
      {
        title: '场景数量',
        value: '20个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '小程序空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '60G'
      },
      {
        title: '一对一专业服务',
        value: ' '
      }
    ]
  },
  {
    name: '旗舰版',
    tips: '适用于综合型项目场景',
    btnText: '立即咨询',
    className: 'l4',
    content: [
      {
        title: '场景数量',
        value: '40个'
      },
      {
        title: '小程序平面AR',
        value: '支持'
      },
      {
        title: '眼镜端空间定位AR',
        value: '支持'
      },
      {
        title: '移动端空间定位AR',
        value: '支持'
      },
      {
        title: '小程序空间定位AR',
        value: '支持'
      },
      {
        title: '空间素材',
        value: '100G'
      },
      {
        title: '一对一专业服务',
        value: ' '
      }
    ]
  }
]

onMounted(async () => {
  const studio_banner: any = await asynchronousRetrieval('webimg/video/studio-banner.gif');
  const web_platform: any = await asynchronousRetrieval('webimg/images/home/web-platform.png');
  const collect_maps: any = await asynchronousRetrieval('webimg/video/collect-maps.mp4');
  const creating_scenes: any = await asynchronousRetrieval('webimg/video/creating-scenes.mp4');
  const rapid_deployment: any = await asynchronousRetrieval('webimg/images/studio/rapid-deployment.png');
  const virtual_real_fusion: any = await asynchronousRetrieval('webimg/video/virtual-real-fusion.mp4');
  const glass_platform_bg: any = await asynchronousRetrieval('webimg/images/home/glass_platform_bg.png');
  loadUrl.value = { studio_banner, web_platform, collect_maps, creating_scenes, rapid_deployment, virtual_real_fusion, glass_platform_bg }

  roleTextList.value[2].url = web_platform
  solutionBannerList.value[0].v_url = collect_maps
  solutionBannerList.value[1].v_url = creating_scenes
  solutionBannerList.value[2].i_url = rapid_deployment
  solutionBannerList.value[3].v_url = virtual_real_fusion
})
</script>
<style scoped lang="less">
.header-video {
  position: relative;
  width: 100%;
  height: 336rem;
  position: relative;
  background-size: 100% 100%;
  background: #3b8dd1;

  img {
    height: 100%;
    object-fit: fill;
    position: absolute;
    right: 0;
    top: 0;
  }

  .video-text {
    width: 1120rem;
    position: absolute;
    top: 74rem;
    left: 62rem;
    font-size: 20rem;
    color: #FFFFFF;
    text-align: left;

    .logo-text {
      font-weight: 600;
      font-size: 40rem;
      color: #FFFFFF;
    }

    .link-room {
      font-size: 24rem;
      color: #FFFFFF;
      margin-top: 10rem;
    }

    .experience-now {
      margin-top: 58rem;
      width: 160rem;
      height: 50rem;
      background: #0675FF;
      border-radius: 25rem;
      padding: 0 6rem 0 16rem;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      text-decoration: none;
      color: #fff;
      font-size: 24rem;

      .experience-btn {
        width: 38rem;
        height: 38rem;
        background: url(~@/assets/images/next-icon.png);
        background-size: 100% 100%;
      }
    }
  }
}

.role-wrap {
  width: 100vw;
  background: #242424;

  padding-top: 64rem;

  .title {
    font-weight: bold;
    font-size: 40rem;
    color: #FFFFFF;
    margin-bottom: 4rem;
  }

  .title-tips {
    font-weight: 400;
    font-size: 24rem;
    color: #FFFFFF;
    margin-bottom: 40rem;
  }

  .role-scene {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    &>div {
      width: 1840rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 36rem 64rem;

      &>div {
        width: 600rem;
        height: 494rem;
        background-image: url(~@/assets/images/studio/AR-platform-m.png);
        background-size: 100% 100%;
        padding: 40rem 70rem;
        box-sizing: border-box;
        text-align: left;

        .role-title {
          font-weight: bold;
          font-size: 32rem;
          color: #FFFFFF;
          margin-bottom: 12rem;
        }

        .role-title-tips {
          font-weight: 400;
          font-size: 24rem;
          color: #FFFFFF;
          line-height: 36rem
        }
      }

      .glass-bg {
        background-image: url('@/assets/images/studio/VR-platform-m.png');
        background-size: 100% 100%;
      }

      .web-bg {
        background-image: url(~@/assets/images/studio/web-platform-m.png);
        background-size: 100% 100%;
      }
    }
  }
}

.advantage {
  background: linear-gradient(180deg, #FFFFFF 0%, #E9EFF6 100%);
  padding-top: 64rem;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  color: #000000;

  .title {
    font-weight: bold;
    font-size: 40rem;
  }

  .title-tips {
    margin: 5rem 0 30rem;
    font-size: 24rem;
  }

  .m1 {
    width: 680rem;

    &>div:first-child {
      width: 100%;
      height: 344rem;
      background: #1F2A34;
      border-radius: 24rem;
      position: relative;
      font-weight: 500;
      padding: 238rem 0 0 38rem;
      box-sizing: border-box;
      overflow: hidden;

      img {
        width: 369rem;
        position: absolute;
        left: 154rem;
        top: 28rem;
        transition: all 1s;
      }

      &>div {
        position: relative;
        z-index: 1;
        text-align: left;
      }

      &>div.t1 {
        font-size: 28rem;
        color: #FFFFFF;
      }

      &>div.t2 {
        font-size: 20rem;
        color: #C0C0C0;
        margin-top: 7rem;
      }

      &::after {
        content: '';
        width: 406rem;
        height: 161rem;
        background: linear-gradient(180deg, rgba(31, 42, 52, 0) 0%, rgba(31, 42, 52, 0.95) 50%, #1F2A34 100%);
        border-radius: 24rem;
        position: absolute;
        left: 136rem;
        top: 136rem;
      }
    }

    &>div:last-child {
      width: 680rem;
      height: 292rem;
      background: #FFFFFF;
      border-radius: 32rem;
      position: relative;
      padding: 188rem 0 0 38rem;
      box-sizing: border-box;
      font-weight: 500;
      text-align: left;
      margin: 16rem 0;

      img {
        width: 350rem;
        height: 144rem;
        position: absolute;
        top: 20rem;
        left: 142rem;
      }

      .t1 {
        font-size: 28rem;
        color: #000000;
        margin-bottom: 8rem;
      }

      .t2 {
        font-size: 20rem;
        color: #7F7F7F;
      }
    }
  }

  .m2 {
    width: 680rem;
    height: 348rem;
    background: url('@/assets/images/studio//m2-bg.png');
    background-size: 100% 100%;
    position: relative;
    padding: 242rem 0 0 38rem;
    font-weight: 500;
    box-sizing: border-box;
    text-align: left;
    overflow: hidden;
    margin-bottom: 16rem;

    .img1 {
      width: 501rem;
      height: 208rem;
      position: absolute;
      top: 22rem;
      left: 90rem;
      transition: all 1s;
    }

    .img2 {
      width: 86rem;
      height: 132rem;
      position: absolute;
      left: 42rem;
      top: 110rem;
      z-index: 1;
      transition: all 1s;
    }

    .img3 {
      position: absolute;
      width: 134rem;
      height: 124rem;
      top: 138rem;
      right: 54rem;
      transition: all 1s;
    }

    .t1 {
      font-size: 28rem;
      color: #FFFFFF;
      margin-bottom: 8rem;
    }

    .t2 {
      font-size: 20rem;
      color: #C0C0C0;
    }
  }

  .m3 {
    text-align: left;

    &>div {
      width: 680rem;
      height: 348rem;
      padding: 242rem 0 0 38rem;
      box-sizing: border-box;
      font-weight: 500;
      margin-bottom: 16rem;

      .t1 {
        font-size: 28rem;
        margin-bottom: 8rem;
      }
    }

    &>div:first-child {
      position: relative;
      background: #FFFFFF;
      border-radius: 24rem;

      .t1 {
        font-size: 28rem;
        color: #000000;
      }

      .t2 {
        font-size: 20rem;
        color: #7F7F7F;
      }
    }

    &>div:last-child {
      background: url(~@/assets/images/studio/m3-r-bg.png);
      background-size: 100% 100%;
      position: relative;
      overflow: hidden;

      .t1 {
        font-size: 28rem;
        color: #fff;
      }

      .t2 {
        font-size: 20rem;
        color: #C0C0C0;
      }
    }

    .m3-icons {
      width: 380rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      left: 52rem;
      top: 46rem;

      img {
        width: 172rem;
        margin-right: 24rem;
      }
    }

    .m3-r {
      position: relative;
      position: absolute;
      top: 0;
      left: 0;

      img {
        width: 680rem;
        height: 63rem;
        display: inline-block;
        position: absolute;
      }

      &>img:first-child {
        top: 34rem;
        left: 56rem;
      }

      &>img:last-child {
        top: 120rem;
        left: -63rem;
      }
    }
  }
}

.solution {
  padding: 72rem 0 68rem;
  background-color: rgba(13, 13, 13, 0.9);

  .title {
    font-weight: 500;
    font-size: 40rem;
    color: #FFFFFF;
    margin-bottom: 4rem;
  }

  .title-tips {
    font-weight: 500;
    font-size: 24rem;
    color: #FFFFFF;
    margin-bottom: 60rem;
  }

  .solution-content {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;

    &>div {
      width: 2352rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 0 80rem;

      &>div {
        position: relative;
        width: 552rem;
        overflow: hidden;
        border-radius: 20rem;

        &>div:first-child {
          border-radius: 7rem;
          overflow: hidden;
        }

        &>div {
          height: 380rem;

          video {
            width: 100%;
            height: 380rem;
            object-fit: fill;
            position: absolute;
            right: 0;
            top: 0;
            border-radius: 20rem;
          }

          img {
            width: 100%;
            height: 380rem;
          }
        }
      }

      &>div>div:last-child {
        height: 286rem;
        margin-top: -28rem;
        padding-top: 82rem;
        box-sizing: border-box;
        background-color: #181818;

        img {
          width: 48rem;
          height: auto;
          vertical-align: middle;
        }

        &>span {
          font-weight: 500;
          font-size: 32rem;
          color: #FFFFFF;
          vertical-align: middle;
        }

        &.no-list>div:not(.line) {
          padding-left: 38rem;
        }

        &.no-list>div:not(.line)>div::before {
          content: none;
        }

        &>div:not(.line) {
          position: relative;
          text-align: center;
          margin-top: 8rem;
          font-weight: 400;
          font-size: 24rem;
          line-height: 44rem;
          padding-left: 68rem;

          &>div {
            position: relative;
          }

          &>div>span {
            position: relative;
          }
        }

        &>div {
          font-weight: 400;
          color: #7F7F7F;
        }
      }
    }
  }
}

.package {
  width: 100vw;
  height: 1104rem;
  background-color: #E9EFF6;
  padding-top: 28rem;

  &>div {
    padding-top: 64rem;

    .title {
      font-weight: 600;
      font-size: 40rem;
      color: #000000;
      line-height: 56rem;
      margin-bottom: 4rem;
    }

    .title-tips {
      font-weight: 500;
      font-size: 24rem;
      color: #5C5C5C;
      line-height: 34rem;
    }
  }

  &>div:last-child {
    background: linear-gradient(180deg, #FFFFFF 0%, #E9EFF6 100%);

    .package-list {
      width: 100vw;
      margin-top: 30rem;
      overflow: hidden;
      overflow-x: auto;

      &>div {
        width: 2004rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 120rem;

        .package-list-icon {
          padding-left: 39rem;
          position: relative;

          &::before {
            content: '';
            background-image: url(~@/assets/images/studio/list-icon.png);
            background-size: 100% 100%;
            width: 31rem;
            height: 22rem;
            position: absolute;
            left: 0;
            top: 5rem;
          }
        }

        .l2>div:first-child {
          background: linear-gradient(180deg, #9FDEFF 0%, #FFFFFF 100%);
        }

        .l3>div:first-child {
          background: linear-gradient(180deg, #F0EBFD 0%, #FFFFFF 100%);
        }

        .l4>div:first-child {
          background: linear-gradient(180deg, #1E84FF 0%, #2E1B62 100%);

          &>div {
            color: #fff;
          }

          .package-btn {
            background: #FFFFFF;
            color: #0675FF;
          }
        }

        &>div {
          // margin: 0 14rem;
          width: 480rem;
          height: 840rem;
          background: linear-gradient(180deg, #F2FAFB 0%, #FFFFFF 100%);
          box-shadow: 0px 4rem 15rem 0px rgba(206, 225, 231, 0.5);
          border-radius: 32rem;
          border: 1px solid #FFFFFF;
          overflow: hidden;

          &>div:first-child {
            height: 384rem;
            background: linear-gradient(180deg, #F2FAFB 0%, #FFFFFF 100%);
            padding-top: 70rem;
            box-sizing: border-box;
            text-align: center;
          }

          &>div:last-child {
            padding: 78rem 42rem 0;
            font-weight: 400;
            font-size: 28rem;
            color: #000000;

            &>div {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 26rem;
            }
          }

          .package-name {
            font-weight: 600;
            font-size: 40rem;
            color: #000000;
            margin-bottom: 34rem;
          }

          .package-tips {
            font-weight: 400;
            font-size: 24rem;
            color: #000000;
            margin-bottom: 54rem;
          }

          .package-btn {
            width: 260rem;
            height: 84rem;
            line-height: 84rem;
            background: #0675FF;
            border-radius: 60rem;
            margin-left: 109rem;
            font-weight: 500;
            font-size: 32rem;
            color: #FFFFFF;
          }
        }
      }
    }
  }
}
</style>