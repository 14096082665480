<template>
  <div class="header">
    <div class="header-box">
      <div class="logo"></div>
      <div class="title-name">
        <div v-for="(item, index) in titleList"
          :class="(item.url != '/' && activePath.includes(item.url)) || (item.url == activePath) ? 'active' : ''"
          @click="toPath(index)" :key="index">{{ item.name }}
        </div>
      </div>
      <a class="enter-studio" href="https://mixedspace.njyjxr.com">
        <div>进入混空studio</div>
        <div class="to-next"></div>
      </a>
    </div>
  </div>
</template>
<script lang="ts" setup>
import router from '@/router';
import { ref, watch, onMounted, onUnmounted } from 'vue'
import { useStore } from 'vuex'

const store = useStore()
const activePath = ref('')
let lastScrollTop = 0;

const titleList: any = [
  {
    name: '首页',
    url: '/'
  },
  {
    name: '混空studio',
    url: '/studio'
  },
  {
    name: '解决方案',
    url: '/solution'
  },
  {
    name: '关于我们',
    url: '/about-us'
  }
]

const toPath = (index: number) => {
  window.scrollTo(0, 0);
  activePath.value = titleList[index].url;
  router.push(titleList[index].url);
  window.localStorage.setItem('activePath', titleList[index].url)
}

const scrollEvent = (e: any) => {
  let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
  (document.querySelector('.header') as any).style.transition = 'all 1s'
  if (scrollTop > lastScrollTop) {
    (document.querySelector('.header') as any).style.opacity = 0;
  } else {
    (document.querySelector('.header') as any).style.opacity = 1;
  }
  lastScrollTop = scrollTop;
}

onMounted(() => {
  window.addEventListener('scroll', scrollEvent, false)
  activePath.value = window.localStorage.getItem('activePath') || ''
})

onUnmounted(() => {
  window.removeEventListener('scroll', scrollEvent, false)
})

watch(() => store.state.activePath, (newState) => {
  if (newState != activePath.value) {
    activePath.value = newState;
  }
})
</script>
<style scoped lang="less">
.header {
  width: 1920rem;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  top: 47rem;
  z-index: 10;
}

.header-box {
  width: 1120rem;
  height: 60rem;
  background: #FFFFFF;
  box-shadow: 0px 4px 12px 0px rgba(3, 41, 88, 0.35), inset 0px -1px 2px 0px rgba(6, 117, 255, 0.13);
  border-radius: 30rem;
  padding: 0 16rem 0 32rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .logo {
    width: 221rem;
    height: 26rem;
    background: url(~@/assets/images/logo.png);
    background-size: 100% 100%;
  }

  .title-name {
    font-weight: 500;
    font-size: 13rem;
    color: #151515;
    width: 338rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    left: -55rem;

    &>div {
      cursor: pointer;
      width: auto;
      height: 40rem;
      line-height: 40rem;
      border-radius: 20rem;
      padding: 0 15rem;
      box-sizing: border-box;
      font-weight: 500;
      font-size: 13rem;
      min-width: 75rem;
      white-space: nowrap;
    }

    &>div.active {
      background: linear-gradient(225deg, #0375FF 0%, #3C96FF 100%);
      color: #FFFFFF;
    }
  }

  .enter-studio {
    width: 176rem;
    height: 40rem;
    background: #0675FF;
    border-radius: 20rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8rem 0 18rem;
    box-sizing: border-box;
    font-weight: 500;
    font-size: 16rem;
    color: #FFFFFF;
    cursor: pointer;
    text-decoration: none;

    .to-next {
      width: 30rem;
      height: 30rem;
      background: url(~@/assets/images/next-icon.png);
      background-size: 100% 100%;
    }
  }
}
</style>