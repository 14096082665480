<template>
  <div class="header-box">
    <img src="@/assets/images/home/logo-light.png" @click="router.push('/'); showList = false" />
    <img v-if="!showList" src="@/assets/images/home/more-icon.png" @click="showList = true" />
    <img v-if="showList" src="@/assets/images/show-list.png" @click="showList = false" />
  </div>
  <div class="header-box-list" v-if="showList" @click="showList = false">
    <div>
      <div v-for="(item, index) in routerList" :key="index" @click="router.push(item.url)">
        <span>{{ item.title }}</span>
        <img src="@/assets/images/home/prev-icon.png" />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter()
const routerList = [
  {
    title: '产品服务',
    url: '/studio'
  },
  {
    title: '解决方案',
    url: '/solution'
  },
  {
    title: '关于我们',
    url: '/about-us'
  },
  {
    title: '合作咨询',
    url: '/counselling'
  }
]
const showList = ref(false)
</script>
<style scoped lang="less">
.header-box {
  width: 750rem;
  height: 72rem;
  background: linear-gradient(90deg, #146BD3 0%, #3A93FD 100%);
  backdrop-filter: blur(50px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 28rem 0 24rem;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 99;

  &>img:first-child {
    width: 236rem;
    height: 30rem;
  }

  &>img:last-child {
    width: 32rem;
    height: 32rem;
  }
}

.header-box-list {
  position: fixed;
  left: 0;
  top: 72rem;
  width: 100vw;
  height: calc(100vh - 72rem);
  background: rgba(0, 0, 0, 0.75);
  z-index: 100;

  // color: #fff;
  // font-size: 28rem;
  // padding: 28rem 36rem 0;
  // box-sizing: border-box;
  &>div {
    padding: 18rem 35rem 0;
    // border-bottom: 2rem solid #757171;
    background: rgba(0, 0, 0, 0.75);
    box-shadow: 0rem 2rem 0rem 0rem rgba(255, 255, 255, 0.5);
  }

  &>div>div {
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80rem;
    border-bottom: 0.39rem solid rgba(167, 163, 163, 0.5);
    font-weight: 500;
    font-size: 28rem;
    color: #FFFFFF;

    img {
      width: 32rem;
      height: 16rem;
    }
  }

  &>div>div:last-child {
    border: none;
  }
}
</style>