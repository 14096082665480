<template>
  <div class="banner-scene">
    <div class="prev" @click="prevEvent"></div>
    <div class="next" @click="nextEvent"></div>
    <div class="banner-wrap">
      <div class="banner-box">
        <div v-for="(item, index) in showList" :key="index" :class="(activeIndex == index) ? 'active' : ''">
          <div>{{ item.title }}</div>
          <!-- <div :style="{ visibility: (activeIndex == index) ? 'visible' : 'hidden' }">{{ item.content }}</div> -->
        </div>
      </div>
      <div>
        <div v-for="(item, index) in bannerData" :class="active_r_i == item.type ? 'active' : ''" :key="index"
          class="view-box">
          <img :class="item.imgs[0].class" :src="item.imgs[0].url"
            :style="{ left: item.imgs[0].size[0] + 'rem', top: item.imgs[0].size[1] + 'rem', opacity: active_r_i == item.type ? 1 : 0 }" />
          <img :class="e.class" v-for="(e, i) in item.imgs.slice(1)" :src="e.url" :style="{ top: e.size[1] + 'rem' }"
            :key="i" />
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, onMounted, onUnmounted, watch } from 'vue'
const props = defineProps({
  bannerData: {
    default: null,
    type: Object
  }
})
const showIndexs: any = ref([2, 3, 0, 1, 2, 3, 0])
const activeIndex: any = ref(3)
const showList: any = ref([])
let timer: any = null;
let timer2: any = null;
let clicks: any = false;
const active_r_i = ref(1)

const prevEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.banner-box') as any).style.transition = 'all .5s';
  (document.querySelector('.banner-box') as any).style.left = '-250rem';
  (document.querySelectorAll('.banner-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all ease-in-out .5s'
  });
  (document.querySelectorAll('.view-box.active>img') as any).forEach((ele: any, i: number) => {
    if (i != 0) {
      ele.style.transition = 'none'
      ele.style.left = props.bannerData[active_r_i.value].imgs[i].size[0] - 100 + 'rem'
      ele.style.opacity = 0
    }
  });
  if (active_r_i.value != 0) {
    active_r_i.value -= 1;
  } else {
    active_r_i.value = props.bannerData.length - 1
  }
  activeIndex.value -= 1;
}

const nextEvent = () => {
  if (clicks) return;
  clicks = true;
  (document.querySelector('.banner-box') as any).style.transition = 'all .5s';
  (document.querySelector('.banner-box') as any).style.left = '-750rem';
  (document.querySelectorAll('.banner-box>div') as any).forEach((ele: any) => {
    ele.style.transition = 'all ease-in-out .5s'
  });
  (document.querySelectorAll('.view-box.active>img') as any).forEach((ele: any, i: number) => {
    if (i != 0) {
      ele.style.transition = 'none'
      ele.style.left = props.bannerData[active_r_i.value].imgs[i].size[0] - 100 + 'rem'
      ele.style.opacity = 0
    }
  });

  if (active_r_i.value != props.bannerData.length - 1) {
    active_r_i.value += 1;
  } else {
    active_r_i.value = 0
  }
  activeIndex.value += 1;
}

onMounted(() => {
  showList.value = showIndexs.value.map((k: number) => props.bannerData[k]);
  (document.querySelectorAll('.view-box') as any).forEach((ele: any, index: number) => {
    const imgs = ele.querySelectorAll('img')
    imgs.forEach((e: any, i: number) => {
      if (i != 0) {
        e.style.left = props.bannerData[index].imgs[i].size[0] - 100 + 'rem'
        e.style.opacity = 0
      }
    });
  });
  setTimeout(() => {
    (document.querySelectorAll('.view-box.active>img') as any).forEach((ele: any, i: number) => {
      if (i != 0) {
        ele.style.transition = 'all ease-in-out .5s'
        ele.style.left = props.bannerData[active_r_i.value].imgs[i].size[0] + 'rem'
        ele.style.opacity = 1
      }
    });
  })
  clearInterval(timer)
  timer = setInterval(() => {
    nextEvent()
  }, 3000)
})

onUnmounted(() => {
  clearInterval(timer)
  clearInterval(timer2)
})

watch(active_r_i, (newState) => {
  clearInterval(timer)
  showIndexs.value = showIndexs.value.map((v: number, i: number) => {
    if (i < 3) {
      if ((newState - (3 - i)) >= 0) {
        return newState - (3 - i)
      } else {
        return (props.bannerData.length + newState) - (3 - i)
      }
    }
    if (i == 3) return newState
    if (i > 3) {
      if ((newState < props.bannerData.length - 3) || (newState + (i - 3) < props.bannerData.length)) {
        return newState + (i - 3)
      } else {
        return newState + (i - 3) - props.bannerData.length
      }
    }
  });
  clearTimeout(timer2)

  timer2 = setTimeout(() => {
    (document.querySelector('.banner-box') as any).style.transition = 'none';
    (document.querySelector('.view-box img') as any).style.transition = 'none';
    (document.querySelector('.banner-box') as any).style.left = '-500rem';
    (document.querySelectorAll('.banner-box>div') as any).forEach((ele: any) => {
      ele.style.transition = 'none'
    });
    (document.querySelectorAll('.view-box.active>img') as any).forEach((ele: any, i: number) => {
      if (i != 0) {
        ele.style.transition = 'all ease-in-out .5s'
        ele.style.left = props.bannerData[active_r_i.value].imgs[i].size[0] + 'rem'
        ele.style.opacity = 1
      }
    });
    showList.value = showIndexs.value.map((k: number) => props.bannerData[k]);
    activeIndex.value = 3
    clicks = false
    clearInterval(timer)
    timer = setInterval(() => {
      nextEvent()
    }, 3000)
  }, 500)
})
</script>
<style scoped lang="less">
.banner-scene {
  position: relative;
  z-index: 1;
  width: 100%;
  overflow: hidden;
}

.prev,
.next {
  width: 54rem;
  height: 54rem;
  background: url(~@/assets/images/home/left.png);
  background-size: 100% 100%;
  position: absolute;
  top: 318rem;
  left: 36rem;
  cursor: pointer;
  z-index: 1;

  &:hover {
    background: url(~@/assets/images/home/rightA.png);
    background-size: 100% 100%;
    transform: rotateY(180deg);
  }
}

.next {
  transform: rotateY(180deg);
  left: auto;
  right: 36rem;

  &:hover {
    transform: rotateY(0deg);
  }
}

.banner-wrap {
  position: relative;
  height: 650rem;
  margin-top: 42rem;
  overflow: hidden;

  &::after {
    content: '';
    width: 100%;
    height: 93rem;
    position: absolute;
    left: 0;
    top: 0;
    background: url('@/assets/images/home/mask.png');
    background-size: 100% 100%;
    opacity: 0.8;
  }

  .banner-box {
    position: absolute;
    top: 30rem;
    left: -500rem;
    // width: 1960rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &>div {
      position: relative;
      width: 250rem;
      border-radius: 8rem;
      padding-top: 10rem;
      box-sizing: border-box;
      text-align: center;
      color: rgba(0, 0, 0, 0.35);

      &>div:first-child {
        font-weight: 500;
        font-size: 26rem;
        margin-bottom: 10rem;
      }
    }

    .active>div:first-child {
      color: #000;
      font-size: 32rem;
    }
  }

  .view-box {
    position: relative;
    width: 2rem;
    height: 2rem;
    position: absolute;
    left: 50%;
    top: 310rem;
    visibility: hidden;

    &.active {
      visibility: visible;
    }

    img {
      position: absolute;
      transition: all .5s;
    }

    &>img:first-child {
      width: 553rem;
      position: absolute;
    }

    .wechat-icon {
      width: 78rem;
      height: 78rem;
    }

    .wechat-icon2 {
      width: 94rem;
      height: 94rem;
    }

    .rokid {
      width: 388rem;
    }

    .ar-rokid {
      width: 440rem;
    }

    .room-bg {
      background: url(~@/assets/images/home/vr-gif-bg.png);
      background-size: 476rem auto;
      background-position: center;
      background-repeat: no-repeat;
    }

    .ar-bg {
      background: url(~@/assets/images/home/ar-gif-bg.png);
      background-size: 498rem auto;
      background-position: center;
      background-repeat: no-repeat;
    }

    .web-bg {
      background: url(~@/assets/images/home/web-gif-bg.png);
      background-size: 553rem auto;
      background-position: center;
      background-repeat: no-repeat;
    }
  }
}
</style>