<template>
  <div class="news">
    <div class="news-banner">
      <img :src="loadUrl.news" />
      <div>公司动态</div>
    </div>
    <div class="news-content">
      <div v-for="(item, index) in newsList" :key="index" @click="toOtherPath(item.relatedHtmlId, item.title)">
        <div>
          <img :src="item.ossPath" />
        </div>
        <div class="content">
          <div class="title">{{ item.title }}</div>
          <div class="detail">{{ item.introduction }}</div>
          <div class="createTime">{{ item.createTime.slice(0, 10) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, onUnmounted, reactive } from 'vue'
import { selectCommonElement, addConsult } from '@/api'
import router from '@/router';
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const newsList: any = ref([])

const toOtherPath = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
}

onMounted(async () => {
  selectCommonElement({ columnId: 5, appId: 2 }).then((res: any) => {
    newsList.value = [...res].map((e: any) => {
      const date = new Date(e.createTime)
      const y = date.getFullYear();
      const m = date.getMonth() + 1;
      const d = date.getDate();
      const h = date.getHours();
      const mx = date.getMinutes();
      const s = date.getSeconds();
      e.createTime = `${y}-${m}-${d} ${h}:${mx}:${s}`
      return e;
    })
  })

  const news: any = await asynchronousRetrieval('webimg/images/about_us/news.png');
  loadUrl.value = { news }
})
</script>
<style scoped lang="less">
.news {
  min-height: calc(100vh - 400rem);
  background: #EFEFEF;

  .news-banner {
    position: relative;
    height: 208rem;
    line-height: 208rem;
    text-align: center;
    font-weight: 600;
    font-size: 40rem;
    color: #FFFFFF;

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
    }

    &>div {
      position: relative;
      z-index: 1;
    }
  }

  .news-content {
    margin: 46rem 0 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    &>div {
      width: 680rem;
      background: #FFFFFF;
      border-radius: 32rem;
      box-sizing: border-box;
      cursor: pointer;
      padding: 24rem 24rem 34rem;
      margin-bottom: 48rem;

      &:hover {
        box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
      }

      img {
        width: 630rem;
        height: 354rem;
        border-radius: 20rem;
      }

      .content {
        width: 632rem;
        text-align: left;
        color: #0A0A0A;
        font-family: PingFangSC-Medium;

        .title {
          font-weight: 600;
          font-size: 30rem;
          color: #414141;
          line-height: 48rem;
          margin-top: 10rem;
        }

        .detail {
          font-weight: 400;
          font-size: 20rem;
          color: #414141;
          line-height: 30rem;
          margin-top: 6rem;
          font-family: PingFangSC-Regular;
        }

        .createTime {
          font-weight: 400;
          font-size: 20rem;
          color: #414141;
          line-height: 30rem;
          margin-top: 8rem;
        }
      }
    }
  }
}
</style>