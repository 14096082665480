<template>
  <div class="education">
    <div class="title-font-style">教育解决方案</div>
    <div class="illustrate">
      <div>针对教育行业课程创建教学场景和虚拟仿真课件，实现教学内容的场景化和教学质量的闭环管理，突出互动性和趣味性，达到高效、有趣、安全的教学效果。</div>
    </div>
    <div class="subtitle">行业痛点</div>
    <div>
      <div class="disadvantage">数实结合的沉浸式教学体验可以实现对传统教学方式的变革，为教育行业带来全新的体验和机会。</div>
    </div>
    <div class="subtitle">方案优势</div>
    <div class="fays">
      <img :src="loadUrl.fays_bg" />
      <div>
        <div>
          <img src="@/assets/images/solution/neirong.png" />
          <div class="title">内容优势</div>
          <div>XR可视化培训相比传统方式，更为直观易学，有效缩短员工培训时间，提高培训效率。</div>
        </div>
        <div>
          <img src="@/assets/images/solution/jishu.png" />
          <div class="title">技术优势</div>
          <div>以互动性+趣味性打造XR虚拟仿真教学平台，实现课程“教-学-练-考”的闭环课程体系。</div>
        </div>
        <div>
          <img src="@/assets/images/solution/fuwu.png" />
          <div class="title">服务优势</div>
          <div>提供内容+设备+软件定制化安装服务，7x24全天候技术服务支持。</div>
        </div>
      </div>
    </div>
    <div class="yycj">
      <div class="subtitle">应用场景</div>
      <div class="yycj-content">
        <video :src="loadUrl.educationMp4" autoplay loop muted playsinline webkit-playsinline></video>
      </div>
    </div>
    <div class="yyal">
      <div class="subtitle">应用案例</div>
      <div class="yyal-content">
        <div @click="toDetail(casesList.relatedHtmlId, casesList.title)">
          <img :src="casesList.ossPath" />
          <div class="content">
            <div class="title">{{ casesList.title }}</div>
            <div class="detail">
              {{ casesList.introduction }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { selectCommonElement } from '@/api'
import { useRouter } from 'vue-router';
import { useStore } from 'vuex'
import { asynchronousRetrieval } from '@/utils'
const loadUrl: any = ref({})

const store = useStore()
const router = useRouter()
const casesList: any = ref({})

const toDetail = (relatedHtmlId: number, title: string) => {
  router.push(`/details?relatedHtmlId=${relatedHtmlId}`)
}

onMounted(async () => {
  selectCommonElement({ columnId: 4, appId: 2 }).then((res: any) => {
    casesList.value = [...res].filter(e => e.industry == 3)[0] || {}
  })

  const fays_bg: any = await asynchronousRetrieval('nfznxr-images/jjfa/fays-bg.png');
  const educationMp4: any = await asynchronousRetrieval('webimg/video/education.mp4');
  loadUrl.value = { fays_bg, educationMp4 }
})
</script>
<style scoped lang="less">
.education {
  padding-top: 60rem;
  overflow: hidden;
  width: 100vw;

  .illustrate {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16rem 0 80rem;

    &>div {
      font-weight: 400;
      font-size: 24rem;
      color: #4A4A4A;
      width: 600rem;
      line-height: 36rem;
    }
  }

  .subtitle {
    position: relative;
    font-weight: 600;
    font-size: 32rem;
    color: #000000;
    line-height: 44rem;
    display: inline-block;
    padding: 0 16rem;

    &::after {
      content: '';
      width: 100%;
      height: 20rem;
      background: rgba(35, 117, 255, 0.2);
      position: absolute;
      left: 0;
      bottom: -5rem;
    }
  }

  .disadvantage {
    width: 600rem;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 8rem;
    margin: 44rem 0 60rem;
    color: #5D5D5D;
    font-size: 20rem;
    line-height: 30rem;
  }

  .hytd-box {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 80rem;

    .hytd {
      width: 1204rem;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;

      &>div {
        width: 394rem;
        font-size: 0;

        &>div {
          padding: 30rem 29rem;
          font-weight: 400;
          font-size: 16rem;
          color: #5C5C5C;
          line-height: 30rem;
          text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
          text-align: left;
          height: 200rem;
          box-sizing: border-box;
          background: #FFFFFF;
          box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
          border-radius: 0 0 8rem 8rem;

          .title {
            font-weight: 600;
            font-size: 22rem;
            color: #333333;
            line-height: 30px;
            text-shadow: 0 0 7rem rgba(0, 0, 0, 0.1);
            font-family: PingFangSC-Medium;
            margin-bottom: 15rem;

            img {
              width: 47rem;
              height: 46rem;
              vertical-align: middle;
            }

            span {
              vertical-align: middle;
            }
          }
        }
      }

      .images {
        width: 393rem;
        height: 330rem;
        background: #F2F4FA;
        box-shadow: 0 0 7rem 0 rgba(0, 0, 0, 0.1);
        border-radius: 8rem 8rem 0 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0;

        img {
          width: 229rem;
          height: 250rem;
        }
      }
    }
  }

  .fays {
    position: relative;
    width: 100vw;
    height: 632rem;
    margin-top: 46rem;
    padding-top: 38rem;
    text-align: left;
    overflow: hidden;
    overflow-x: auto;
    box-sizing: border-box;

    &>img {
      width: 1632rem;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    &>div {
      width: 1392rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 120rem;

      &>div {
        width: 448rem;
        height: 556rem;
        background: rgba(255, 255, 255, 0.9);
        border-radius: 8rem;
        backdrop-filter: blur(10rem);
        padding: 0rem 0 40rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        font-weight: 400;
        font-size: 20rem;
        color: #5D5D5D;
        line-height: 30rem;
        text-align: center;
        overflow: hidden;
        text-align: left;

        &>div {
          padding: 0 32rem;
        }

        .title {
          font-weight: 600;
          line-height: 30rem;
          font-family: PingFangSC-Medium;
          margin: 42rem 0 26rem;
          text-align: left;
          width: 100%;
          padding-left: 32rem;
          box-sizing: border-box;
          font-size: 28rem;
          color: #000000;
        }

        img {
          width: 100%;
          height: 302rem;
        }
      }
    }
  }

  .yycj {
    padding-top: 64rem;

    .yycj-content {
      width: 100%;
      height: 382rem;
      margin-top: 48rem;
      display: flex;
      justify-content: center;
      align-items: center;

      video {
        width: 680rem;
        height: 100%;
        object-fit: fill;
        border-radius: 24rem;
        border: 1px solid #979797;
      }
    }
  }

  .yyal {
    padding-top: 64rem;
    overflow: hidden;

    .yyal-content {
      margin: 46rem 0 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;

      &>div {
        width: 680rem;
        background: #FFFFFF;
        border-radius: 32rem;
        box-sizing: border-box;
        cursor: pointer;
        padding: 24rem 24rem 34rem;
        margin-bottom: 48rem;

        &:hover {
          box-shadow: 0px 10px 12px 0px rgba(46, 118, 255, 0.2), 0px 0px 4px 0px rgba(46, 118, 255, 0.2);
        }

        img {
          width: 630rem;
          height: 354rem;
          border-radius: 20rem;
        }

        .content {
          width: 632rem;
          text-align: left;
          font-weight: 600;
          font-size: 16rem;
          color: #0A0A0A;
          line-height: 22rem;
          font-family: PingFangSC-Medium;

          .title {
            font-weight: 600;
            font-size: 30rem;
            color: #414141;
            line-height: 48rem;
            margin-top: 10rem;
          }

          .detail {
            font-weight: 400;
            font-size: 20rem;
            color: #414141;
            line-height: 30rem;
            margin-top: 6rem;
            font-family: PingFangSC-Regular;
          }
        }
      }
    }

    .pagination {
      margin: 50rem 0 57rem;
      display: flex;
      justify-content: center;
      align-items: center;

      &>div {
        width: 24rem;
        height: 24rem;
        line-height: 24rem;
        border-radius: 2rem;
        font-weight: 400;
        font-size: 14rem;
        color: #999999;
        margin: 0 5rem;
        text-align: center;
        cursor: pointer;
      }

      &>div.active {
        background: #F7F7F7;
        color: #414141;
      }

      .prev {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
      }

      .next {
        width: 16rem;
        height: 16rem;
        background-image: url(~@/assets/images/solution/left-c.png);
        background-size: 100% 100%;
        transform: rotate(180deg);
      }
    }
  }
}
</style>