import { getOssUrl } from "@/api";
export const findSequences = (text: string) => {
  const regex = /webstaticvideo.*?mp4/gi;
  const matches = text.matchAll(regex);

  // 将匹配结果转换为数组
  const sequences = Array.from(matches, (match) => match[0]);

  return sequences;
};

export const findSequences2 = (text: string) => {
  const regex = /source src=".*?"/gi;
  const matches = text.matchAll(regex);

  // 将匹配结果转换为数组
  const sequences = Array.from(matches, (match) => match[0].slice(0, -1));

  return sequences;
};

export const asynchronousRetrieval = (key: string) => {
  return new Promise((resolve) => {
    getOssUrl({ key }).then((res) => {
      if(typeof res == 'string') {
        const data = decrypt(res)
        resolve(data);
      } else{
        resolve('');
      }
    });
  });
};

const decrypt = (word: any) => {
  const CryptoJS = (window as any).CryptoJS;
  const key = CryptoJS.enc.Utf8.parse("1234567890123456");
  // 使用CryptoJS进行AES解密
  const options = {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7,
  };
  const decryptedText = CryptoJS.AES.decrypt(word, key, options).toString(
    CryptoJS.enc.Utf8
  );
  return decryptedText;
};

