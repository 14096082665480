import request from "./request";
// 提交意见表单
export function addConsult(data: any) {
  data.appId = 2;
  return request({
    url: "/addConsult",
    method: "post",
    data,
  });
}

// 查询模板页面接口
export function selectCommonElement(data: any) {
  return request({
    url: `/selectCommonElement?appId=${data.appId}&columnId=${data.columnId}&createTimeOrder=${data.createTimeOrder == undefined ? 1 : data.createTimeOrder}`,
    method: "get",
  });
}

// 通过id获取新闻详情
export function getStaticHtml(data: any) {
  return request({
    url: `/getStaticHtml?id=${data.id}`,
    method: "get",
  });
}

// 获取url数组
export function getOssUrls(data: any) {
  return request({
    url: '/getOssUrls',
    method: "post",
    data,
  });
}

// 获取url加密
export function getOssUrl(data: any) {
  return request({
    url: '/getOssUrl?key=' + data.key,
    method: "get",
    data,
  });
}